<template>
  <div>
    <div class="ew-chat-room">
      <div class="ew-chat-room-head">
        <div class="title">{{ $t('video-chat-room[\'chat room\']') }}</div>
        <button class="control control-button-primary launch"
                :class="{ 'disabled': isVideoChatActive(videoChatState) && canJoinVideoChatRoom || !this.isContactTeamMember}"
                :disabled="isVideoChatActive(videoChatState) && canJoinVideoChatRoom || !this.isContactTeamMember"
                @click="onVideoChatRoomButtonClick"
        >{{ $t('video-chat-room[\'Start chat\']') }}</button>
      </div>
      <div class="ew-chat-room-body">
        <div class="shade">
          <div class="ew-chat-room-body-text">
            {{ $t('video-chat-room.joinChatRoomText') }}
          </div>
          <button    :class="{ 'disabled': !isVideoChatActive(videoChatState)}"
                     :disabled="!isVideoChatActive(videoChatState)"
                     @click="onVideoChatRoomButtonClick" class="control control-button-secondary come-in">{{ $t('button.enter') }}</button>
        </div>
      </div>
    </div>
    <div class="profile-wrapper profile-wrapper-skeleton" v-if="!profile.id">
      <mu-circular-progress class="demo-circular-progress" :size="26"></mu-circular-progress>
    </div>
    <div class="profile-wrapper" v-if="profile.id">
      <profileHeadNav :leftNav="false"
                      :isFavorite="profile.is_favorite"
                      @promoFavoriteContactEvent="setFavContact"
                      @promoGoToOwnerMessages="navSelect('messages')"
                      :video_stream_url="profile.video_stream_url"
      ></profileHeadNav>
      <div class="video-conf"></div><!-- TODO: remove it? -->
      <div class="profile">
        <div class="head-block">
          <div class="avatar round-radius">
            <img :src="setAvatar(profile.photo_url)" alt="image"/>
          </div>
          <div class="head-content">
            <div class="title contact-title">
              <span v-if="profile.name">{{ profile.name }} </span>
              <span v-if="profile.surname">{{ profile.surname }}</span>
              <span v-if="!profile.name && !profile.surname">{{ $t('contacts.info.noname') }}</span>
            </div>
            <div class="contact-subtitle" v-if="profile.company_position">{{ profile.company_position }}</div>
            <div class="subtitle contact-subtitle" v-if="profile.company_name">{{ profile.company_name }}</div>
            <div class="info contact-info" v-if="profileGeoInfo">{{ profileGeoInfo }}</div>
          </div>
        </div>
        <div class="nav">
        <span class="tab cur-p" :class="{'highlight': navSelected === 'profile'}"
              @click="navSelect('profile')">{{ $t('title.profile') }}</span>
          <span class="tab cur-p" :class="{'highlight': navSelected === 'calendar'}"
                @click="navSelect('calendar')">{{ $t('title.calendar') }}</span>
          <span class="tab cur-p" :class="{'highlight': navSelected === 'messages'}"
                @click="navSelect('messages')">{{ $t('title.messages') }}</span>
          <span class="tab cur-p" :class="{'highlight': navSelected === 'notes'}"
                @click="navSelect('notes')">{{ $t('title.notes') }}</span>
        </div>
        <div class="content">
          <div :class="{'truncated-text': false}"
               class="description ws-pre-wrap"
               v-if="profile.company_description && navSelected === 'profile'"
          >
            <truncate action-class="read-more"
                      :length="180"
                      :less="$t('button.less')"
                      :clamp="$t('button.more')"
                      type="html"
                      :text="generateLinks(profile.company_description)"></truncate>
          </div>
        </div>
      </div>

      <!-- Contact tags -->
      <div class="field-block field-block-contact-tags"
           v-if="profile.tags && profile.tags.length && navSelected === 'profile'">
        <div class="field-header">
          <div class="field-title">{{ $t('eventPage.edit.tagManagement.popupTitleContact') }}</div>
          <div class="action" v-if="profile.tags && profile.tags.length > 4">
            <a class="btn cur-p" @click="showAllTags = !showAllTags">
              <span v-if="showAllTags">{{ $t('button.less') }}</span>
              <span v-else>{{ $t('button.seeAll') }}</span>
            </a>
          </div>
        </div>
        <div class="field-content field-content-tags">
              <span class="tag" v-for="(tag, index) in profile.tags"
                    :key="'profileContactTag_' + profile.id + '_' + index"
                    v-show="index < 4 || showAllTags"
              >{{ tag.name }}</span>
        </div>
      </div>

      <!-- Documents -->
      <document-list
        class="field-block field-block-documents"
        :class="{
            'show-action': !showDocuments,
            'show-all': showDocuments
          }"
        :docs="profile.files"
        :showAll="showDocuments"
        @seeAllDocuments="showAllDocuments()"
        v-if="profile.files && profile.files.length && (navSelected === 'profile')"
      ></document-list>


      <!-- Notes -->
      <notes-list
        class="field-block field-block-notes"
        v-if="noteList && noteList.length && (navSelected === 'profile' || navSelected === 'notes')"
        :notes="noteList"
        :showAll="navSelected === 'notes'"
        :showActionButton="navSelected !== 'notes'"
        @seeAllNotes="navSelect('notes')"
      ></notes-list>

      <div class="message-block" v-if="navSelected === 'messages'">
        <div class="field-block">
          <div class="field-header">
            <div class="title">{{ $t('title.messages') }}</div>
          </div>
        </div>
        <messages
          :contact-id="getCompanyContact.id"
          :current-contact="getCompanyContact"
        ></messages>
      </div>

      <div class="field-block"
           v-if="navSelected === 'calendar'"
      >
        <div class="field-header">
          <div class="field-title">{{ $t('title.calendar') }}</div>
        </div>
        <div class="field-content field-content-meetings">
          <ContactSchedule
            :contactData="profile"
            :promoPage="promoPage"
          ></ContactSchedule>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import profileHeadNav from './profile-head-nav';
import truncate from 'vue-truncate-collapsed';
import { DateTimeHelper } from '@/helpers/date-time.helper';
import { FileNameHelper } from '@/helpers/filename.helper';
import DocumentList from '../documents/documentList';
import NotesList from '../notes/notesList';
import Statistics from '@/services/statistics';
import ContactSchedule from "@/_modules/contacts/components/contact-schedule/contact-schedule";
import ChatHelper from "@/_modules/chat/helpers/chat.helper";
import eventDiscoveryService from "@/_services/event-discovery.service";
import EventHelper from "@/_helpers/event.helper";
import { TimeStatus } from "@/_types/time-status.enum";
import messages from '@/_modules/promo/components/messages/messages.vue';

const beforeRouteEnter = { to: null, from: null };
const DIRECT_MESSAGE_MAX_LENGTH = 1000;

export default {
  name: 'promo-profile',
  components: {
    profileHeadNav,
    truncate,
    DocumentList,
    NotesList,
    ContactSchedule,
    messages
  },
  computed: {
    ...mapState({
      promoProfile: state => state.promoStore.profile,
      promoPage: state => state.promoStore.promoPage,
      noteListAll: state => state.noteStore.noteList.List,
      eventInfo: state => state.eventStore.eventInfo,
    }),
    ...mapGetters({
      getMessagesStateByUserId: '_messageStore/getMessagesStateByUserId',
      contact: 'promoPageStore/contact', // TODO: rename to myself
      messages_count: 'notificationsStore/messagesCount',
      promoPageByExternalId: 'promoStore/promoPageByExternalId',
      contactInfo: 'promoPageStore/contact',
      userInfo: '_userStore/user',
      event: '_eventStore/event',
      getCompanyVideoChatStateByExternalId: 'meetingRoomsStore/getCompanyVideoChatStateByExternalId',
    }),
    isDirectMessageLengthExceeded() {
      return this.messageText.length >= DIRECT_MESSAGE_MAX_LENGTH;
    },
    messagesState() {
      if (!this.user_id) {
        return null;
      }
      return this.getMessagesStateByUserId(this.user_id);
    },
    messageListAll() {
      const messagesState = this.messagesState;
      return (messagesState && messagesState.data) || null;
    },
    profileGeoInfo() {
      const result = [this.profile.city, this.profile.country].filter(x => x);
      return result.join(', ');
    },
    externalId() {
      return this.$route.params.external_id ? this.$route.params.external_id : null;
    },
    company() {
      return this.promoPageByExternalId(this.externalId);
    },
    attachedContacts() {
      const company = this.company;
      return (company && company.attached_contacts) || [];
    },
    isContactTeamMember() {
      const contactId = this.contactId;
      const attachedContacts = this.attachedContacts;
      if (!contactId || !attachedContacts || !attachedContacts.length) {
        return false;
      }
      return !!(attachedContacts.find(c => c.contact.id === contactId));
    },
    contactId() {
      return (this.contactInfo && this.contactInfo.id) || null;
    },
    getCompanyContact() {
      return this.promoProfile.attached_contacts.find(obj => {
        return obj.role === "owner"? obj.contact : null
   })
    },
    videoChatState() {
      return this.getCompanyVideoChatStateByExternalId(this.externalId);
    },
    canJoinVideoChatRoom() {
      const contactId = this.contactId;
      const videoChatState = this.videoChatState;
      const isContactTeamMember = this.isContactTeamMember;

      if (!contactId) {
        return false;
      }

      const isVideoChatActive = this.isVideoChatActive(videoChatState);
      if (eventDiscoveryService.isConnected() && isContactTeamMember && !isVideoChatActive) {
        return true;
      }

      return isVideoChatActive;

    },
    eventId() {
      return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
    },
  },
  watch: {
    navSelected: {
      immediate: true,
      handler() {
        this.markNoticedMessages();
      }
    },
    '$route.params.contact_id': {
      immediate: true,
      deep: true,
      handler() {
        this.event_id = Number(this.$route.params.eventId);
        this.contact_id = Number(this.$route.params.contact_id);
        this.callProfile();
      }
    },
    messages_count: {
      handler() {
        this.setMessages();
      }
    },
    contact_id: {
      immediate: true,
      handler() {
        Statistics.contactView({
          eventId: this.event_id,
          contactId: this.contact_id,
          externalId: this.external_id,
        }, beforeRouteEnter);
      }
    },
    promoProfile: {
      deep: true,
      handler() {
        if (!this.promoProfile)
          return;
        if (this.promoProfile.attached_contacts) {
          this.promoProfile.attached_contacts.forEach(item => {
            if (item.contact.id === this.contact_id) {
              this.profile = item.contact;
              this.user_id = item.contact.user.id;
              this.setNote();
              this.setMessages();
            }
          });
        }
      }
    },
    messageListUser: {
      deep: true,
      handler() {
        this.messageList = this.messageListUser.List;
        this.markNoticedMessages();
      }
    },
    setNavSelected: {
      immediate: true,
      deep: true,
      handler() {
        this.navSelect(this.setNavSelected)
      }
    },
    eventInfo: {
      immediate: true,
      deep: true,
      handler() {
        this.enableMessages();
      }
    }
  },
  props: {
    setNavSelected: {
      type: String,
      handler() {
        this.navSelect(this.setNavSelected)
      }
    }
  },
  data() {
    return {
      showAllTags: false,
      profile: {},
      noteFav: true,
      leftNav: false,
      event_id: null,
      noteList: {},
      messageList: [],
      navSelected: 'profile',
      messageText: '',
      user_id: null,
      contact_id: null,
      external_id: null,
      userName: '',
      showTeam: false,
      showDocuments: false,
      showNotes: false,
      limit: 20,
      loadMessagesInterval: null,
      isShowMessageForm: false,
      messagesLoading: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    beforeRouteEnter.to = to;
    beforeRouteEnter.from = from;
    next();
  },

  created() {
    this.event_id = Number(this.$route.params.eventId);
    this.external_id = this.$route.params.external_id;
    this.contact_id = Number(this.$route.params.contact_id);
  },
  beforeMount() {
    this.event_id = Number(this.$route.params.eventId);
    this.external_id = this.$route.params.external_id;
    this.contact_id = Number(this.$route.params.contact_id);

    let payload = {
      event_id: this.event_id,
      contact_id: this.contact_id
    };
    this.$nextTick(() => {
      this.$store.dispatch('promoStore/getProfileByContactId', payload)
    })

  },
  mounted() {
    this.event_id = Number(this.$route.params.eventId);
    this.external_id = this.$route.params.external_id;
    this.contact_id = Number(this.$route.params.contact_id);

    this.setNote();
    this.setMessages();
  },
  methods: {
    callProfile() {
      if (!this.event_id && !this.contact_id) {return}
      let payload = {
        event_id: this.event_id,
        contact_id: this.contact_id
      };

      this.$store.dispatch('promoStore/getProfileByContactId', payload)
    },
    addOffset(dateTimeString) {
      return DateTimeHelper.getMomentWithOffset(dateTimeString);
    },
    setMessageSenderAvatar(message) {
      // If the message is is_mine, take data from contact entity
      // otherwise from message.contact
      if (message.is_mine && this.contact && this.contact.photo_url) {
        return 'url(' + this.contact.photo_url + ')';
      } else if (message && message.contact && message.contact.photo_url) {
        return 'url(' + message.contact.photo_url + ')';
      }
      // fallback to default avatar
      return 'url(' + require('../../../../assets/images/no-avatar-60x60.svg') + ')';
    },
    docName(val) {
      return FileNameHelper.getFileName(val);
    },
    docType(val) {
      return FileNameHelper.getFileExtension(val);
    },
    setNote() {
      Promise.all([
        this.$store.dispatch('noteStore/callNoteListAll',
          {
            event_id: this.event_id,
            user_id: this.user_id
          })
      ]).then(() => {
        this.noteList = this.noteListAll;
      });
    },
    async setMessages() {
      if (!this.event_id || !this.user_id) {return}

      this.messagesLoading = true;
      await this.$store.dispatch('_messageStore/requestUserMessages', {
        userId: this.user_id,
      });
      this.messagesLoading = false;

      if (this.messageListAll && this.messageListAll.List) {
        this.messageList = this.messageListAll.List;
        this.markNoticedMessages();
      }

      this.$nextTick(async () => {
        if (this.messageList && !this.messagesLoading) {
          const container = await this.$el.querySelector('#container');
          if (container) {
            if (this.limit <= 5) {
              container.scrollTop = 200;
            } else {
              container.scrollTop = container.scrollHeight;
            }
          }
        }
      });
    },
    async sendMessage() {
      if (this.messageText && this.messageText.trim() && !this.isDirectMessageLengthExceeded) {

        await this.$store.dispatch('_messageStore/sendMessage', {
          eventId: this.event_id,
          userId: this.user_id,
          text: this.messageText.trim()
        })

        await this.setMessages();
        this.messageText = '';
      }
    },
    setAvatar(url) {
      if (url) {
        return url
      } else {
        // return 'src/assets/images/no-avatar-60x60.svg'
        return require('../../../../assets/images/no-avatar-60x60.svg')
      }
    },
    navSelect(value) {
      this.navSelected = value || 'profile';

      if (this.navSelected === 'messages') {
        if (!this.$refs['msgContainer']) {return}
        this.$nextTick(() => {
          let msgContainer = this.$refs['msgContainer'].$el;
          if (msgContainer) {
            if (this.limit <= 5) {
              msgContainer.scrollTop = 200;
            } else {
              msgContainer.scrollTop = msgContainer.scrollHeight;
            }
          }
        })
      }

    },

    markNoticedMessages() {
      if (this.navSelected !== 'messages' || !this.messageList || !this.messageList.length) {
        return;
      }

      const messageIds = this.messageList.map(message => message.id);
      this.$store.dispatch('notificationsStore/setNoticedMessages', messageIds);
      this.$store.dispatch('contactsStore/clearContactUnreadMessages', this.contact_id);
    },

    setFavContact(isFav) {
      let payload = {
        eventId: this.event_id,
        contactId: this.contact_id
      };
      if (isFav) {
        this.$store.dispatch('contactsStore/addFavContact', payload);
      } else {
        this.$store.dispatch('contactsStore/removeFavContact', payload);
      }
    },
    showAllTeam() {
      this.showTeam = !this.showTeam;
      // this.$emit("promoShowTeam", this.showTeam);
    },
    showAllDocuments() {
      this.showDocuments = !this.showDocuments;
      // this.$emit("promoShowDocuments", this.showDocuments);
    },
    showAllNotes() {
      this.showNotes = !this.showNotes;
      // this.$emit("promoShowNotes", this.showNotes);
    },
    toggleNoteFav(id, note) {
      if (!note.is_favorite) {
        this.$store.dispatch('noteStore/removeFav', id)
      } else {
        this.$store.dispatch('noteStore/addFav', id)
      }

    },
    loadMore() {
      if (!this.messagesLoading) {
        if (this.messageListUser && this.limit <= this.messageListUser.Total) {
          this.limit += 20;
          this.setMessages();
        }
      }
    },
    enableMessages() {
      const dateStart = this.$moment(this.eventInfo.date_start);
      this.isShowMessageForm = this.$moment().isSameOrAfter(dateStart, 'minutes');
    },
    generateLinksWithImagePreviews(text) {
      return ChatHelper.createLinksWithImagePreviews(text)
    },
    generateLinks(text) {
      return ChatHelper.createLinks(text)
    },
    isVideoChatActive(videoChatState) {
      const currentTimestamp = Math.floor((new Date()).getTime() * 0.001);
      return eventDiscoveryService.isConnected()
        && !!videoChatState
        && videoChatState.expire > currentTimestamp
        && videoChatState.isPublished
        && !!videoChatState.participants[0];
    },
    onVideoChatRoomButtonClick() {
      if (!this.canJoinVideoChatRoom) {
        return;
      }

      const contactId = this.contactId;
      const videoChatState = this.videoChatState;
      const isContactTeamMember = this.isContactTeamMember;

      const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);

      if (eventTimeStatus === TimeStatus.PAST || eventTimeStatus === TimeStatus.FUTURE) {
        this.$store.dispatch('_eventStore/setIsChatRoomTimeCheckDialogVisible', true);
        return;
      }

      if (!videoChatState && isContactTeamMember) {
        const meetingRoomConfig = {
          type: 'company',
          eventId: this.eventId,
          contactId: contactId,
          moderatorContactId: contactId,
          externalId: this.externalId,
        };
        this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
      } else if (videoChatState) {
        const meetingRoomConfig = {
          type: 'company',
          eventId: this.eventId,
          contactId: contactId,
          moderatorContactId: videoChatState.moderatorContactId,
          externalId: this.externalId,
        };
        this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
      }
    },
  }
};
</script>

<style scoped lang="scss">

.scroll-height {
  max-height: 350px;
  padding-bottom: 20px;
}

.profile-wrapper {
  width: 392px;
  height: auto;
  box-shadow: 5px 5px 15px #3754aa1a;
  border-radius: 10px;
  background-color: $ew-white;
}

.field-block {
  padding: 10px 25px;
  position: relative;
  overflow: hidden;

  .field-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 8px;

    .title {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      opacity: 0.8;
      position: relative;

      &::before {
        display: block;
        position: absolute;
        left: -300%;
        bottom: 2px;
        content: "";
        border-bottom: 1px solid #eaeaea;
        width: 300%;
      }
    }

    .btn {
      text-align: left;
      font-size: 12px;
      line-height: 14px;
      color: $ew-blue;
    }
  }

  .enumerable {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    overflow: hidden;
    height: 100px;
  }

  .flex-column-center {
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    align-items: center;
  }

  .field-content {
    padding: 16px 0;

    &-meetings {
      box-shadow: none;
      border-radius: 0;
      background-color: transparent;
    }

    .doc {
      .doc-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 33px;
      }
    }

    .square-radius {
      border-radius: 5px;
    }

    .round-radius {
      border-radius: 50%;
    }

    .avatar {
      width: 60px;
      height: 60px;
      position: relative;
      overflow: hidden;
      margin: 0 auto;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .title {
      margin-top: 6px;
      max-width: 70px;
      margin-left: auto;
      margin-right: auto;
    }

    .title,
    .subtitle {
      text-align: center;
      font-weight: 300;
      font-size: 10px;
      color: $ew-black;
      opacity: 0.8;
    }
  }
}

.profile {
  padding: 0 25px;
  margin-bottom: 2rem;

  &::before {
    display: block;
    content: "";
    height: 1px;
    width: 50px;
    background-color: $ew-gray-lightest;
    position: relative;
    top: 0;
    left: -50px;
  }

  .head-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .square-radius {
      border-radius: 5px;
    }

    .round-radius {
      border-radius: 50%;
    }

    .avatar {
      width: 90px;
      height: 90px;
      min-width: 90px;
      position: relative;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .head-content {
      padding-left: 20px;
      text-align: left;
      color: $ew-black;
      overflow: hidden;

      .contact-title,
      .contact-subtitle {
        max-width: 100%;
      }
    }
  }

  .nav {
    display: flex;
    justify-content: space-between;
    padding: 32px 0 0;
    font-size: 12px;
    line-height: 14px;

    .tab {
      text-align: left;
      font-weight: 400;
      color: $ew-black;
      text-decoration: none;
      opacity: 0.8;
    }

    .highlight {
      border-bottom: 1px solid $ew-blue;
      color: $ew-blue;
    }
  }

  .content {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    line-height: 14px;

    .description {
      text-align: left;
    }
  }

}

html[lang=ru] .nav a {
  font-size: 12px;
}

.content-item {
  margin-bottom: 26px;
  width: 25%;

  &:nth-child(5n+5) {
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.notes {
  margin-top: 10px;

  &:nth-child(2) {
    margin-top: 0;
  }

  .content-item {

    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    color: $ew-black;
    text-align: left;
    font-size: 12px;
    line-height: 14px;

    .content {
      margin-left: 37px;
    }

    .content .title {
      font-weight: 700;
    }

    .content .subtitle {
      font-weight: 400;
    }

  }

  .content-text {
    text-align: left;

    .text_title {
      margin: 14px 0 8px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;
    }

    .text {
      margin: 8px 0;
      text-align: left;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.show-all {
  height: auto !important;
}

.fav {
  margin-left: auto;
}

.profile-wrapper-skeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.inner-circular-progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.field-block-contact-tags {
  .field-content {
    margin: 0 -12px;
    display: flex;
    flex-flow: row wrap;
  }

  .action .btn {
    font-size: 12px;
  }

  .tag {
    border-radius: 99px;
    padding: 7px 12px;
    margin: 0 5px 5px 0;
    border-style: solid;
    border-width: 1px;
    border-color: $ew-gray-weak;
    display: inline-block;
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ew-chat-room {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;

  &-head {
    height: 36px;
    background: $ew-white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;

    .title {
      font-family: $ew-main-font;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .shade {
    background-color: rgba($ew-black, 0.6);
    height: 100%;
  }

  &-body {
    background: url("~@/assets/images/video-chat/stol-dlya-peregovorov.png") no-repeat center;
    height: 190px;
    width: 392px;
    background-size: cover;


    &-text {
      color: $ew-white;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      padding: 52px 40px 27px;
    }

    .come-in {
      min-width: 80px;
      height: 22px;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      margin: auto;
      cursor: pointer;

      &:hover {
        background-color: lighten($ew-blue, 5%);
      }
    }
  }

  &-image {
    width: 100%;
    height: 100%;
  }

  .launch {
    min-width: 80px;
    height: 22px;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: lighten($ew-red, 5%);
    }
  }

  .flicker {
    animation: flickerAnimation 3s infinite;
  }

  .disabled {
    background-color: $ew-gray-weak;


    &:hover {
      background-color: $ew-gray-weak;
    }
  }

  @keyframes flickerAnimation { /* flame pulses */
    0% { opacity: 1; }
    50% { opacity: 0.2; }
    100% { opacity: 1; }
  }
}
/deep/ .message-block {

  .messages-list {
    padding-bottom: 0;
  }

  .messages-list-scroll {
    position: relative;
    max-height: 980px;
    min-height: 392px;
  }

  .new-message-form {
    position: relative;
    width: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
</style>
